import TypeDocumentResidency from './TypeDocumentResidency'
export default class ResidencyDocument {
  id!: number
  document_description!: string
  date_presented!: Date
  comment!: string
  document_type_id!: string
  residency_id!: number
  document_status!: string
  fileUrl!: string
  upload!: File
  document_type!: TypeDocumentResidency
}
